import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ message }) => (
  <div className="loading-spinner-container">
    <div className="loading-spinner"></div>
    {message && <p className="loading-message">{message}</p>}
  </div>
);

export default LoadingSpinner;