import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRobot, FaPaperPlane } from 'react-icons/fa';
import HeaderLoggedIn from './HeaderLoggedIn';
import './Dashboard.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';

function Dashboard() {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [suggestedReplies, setSuggestedReplies] = useState({});
  const [newComments, setNewComments] = useState({});
  const navigate = useNavigate();
  const [nextUrl, setNextUrl] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [highlightedComments, setHighlightedComments] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [animationMessage, setAnimationMessage] = useState('');
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    checkAuthAndFetchData();
  }, []);

  const checkAuthAndFetchData = async () => {
    try {
      const response = await axios.get('/api/check_auth');
      if (response.data.authenticated) {
        const userStatusResponse = await axios.get('/api/user_status');
        if (!userStatusResponse.data.initialised) {
          showAnimation("Setting up your dashboard");
          await initializeUser();
        } else {
          await fetchLatestMedia();
        }
        await fetchMediaAndComments('/api/media_and_comments', true);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
      navigate('/login');
    }
  };

  const initializeUser = async () => {
    try {
      await axios.post('/api/initialize_user');
    } catch (error) {
      console.error('Error initializing user:', error);
      setError('Failed to initialize user. Please try again.');
    } finally {
      setLoading(false);
      hideAnimation();
    }
  };

  const showAnimation = (message) => {
    setAnimationMessage(message);
    setAnimationVisible(true);
  };
 
  const hideAnimation = () => {
    setAnimationVisible(false);
  };

  const fetchLatestMedia = async () => {
    try {
      await axios.get('/api/fetch_latest_media');
    } catch (error) {
      console.error('Error fetching latest media:', error);
      setError('Failed to fetch latest media. Please try again.');
    }
  };

  const fetchMediaAndComments = async (url = '/api/media_and_comments') => {
    try {

      let fetchUrl = url;
      if (url === '/api/media_and_comments' && nextUrl) {
        fetchUrl = `${url}?next=${encodeURIComponent(nextUrl)}`;
      }
      if (nextUrl) { setLoadingMore(true);}
      else {setLoading(true);}
      
      const response = await fetch(fetchUrl, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch media and comments');
      }
      const { data, paging } = await response.json();
      
      setMedia(prevMedia => {
        const updatedMedia = [...prevMedia];
        data.forEach(newItem => {
          const existingIndex = updatedMedia.findIndex(item => item.id === newItem.id);
          if (existingIndex !== -1) {
            updatedMedia[existingIndex] = {
              ...updatedMedia[existingIndex],
              ...newItem,
              comments: [...updatedMedia[existingIndex].comments, ...newItem.comments]
            };
          } else {
            updatedMedia.push(newItem);
          }
        });
        return updatedMedia;
      });

      setNextUrl(paging.next);
      setHasMore(!!paging.next);
    } catch (error) {
      console.error('Error fetching media and comments:', error);
      setError('Failed to fetch media and comments. Please try again.');

      const isTokenValid = await checkTokenValidity();
      if (!isTokenValid) {
        console.log('Token is invalid. Logging out...');
        handleLogout();
      }
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };
  
  const loadMoreMedia = () => {
    if (!loadingMore && nextUrl) {
      fetchMediaAndComments();
    }
  };

  const organizeComments = (comments) => {
    const commentMap = new Map();
    const topLevelComments = [];

    console.log('Comments before organizing:', comments);

    // First pass: create a map of all comments
    comments.forEach(comment => {
      commentMap.set(comment.id, { ...comment, replies: [] });
    });

    // Second pass: organize comments into a tree structure
    comments.forEach(comment => {
      if (comment.replies && comment.replies.data.length > 0) {
        // This comment has replies, add each reply to its parent's replies
        comment.replies.data.forEach(reply => {
          if (commentMap.has(reply.id)) {
            commentMap.get(comment.id).replies.unshift(commentMap.get(reply.id)); // Add replies to the beginning to sort from oldest to newest
          }
        });
      }

      // Check if this comment is a top-level comment
      const isTopLevel = !comments.some(c => c.replies && c.replies.data.some(reply => reply.id === comment.id));
      if (isTopLevel) {
        topLevelComments.push(commentMap.get(comment.id));
      }
    });
    console.log('Organized comments structure:', topLevelComments);

    return topLevelComments;
  };

  const checkTokenValidity = async () => {
    try {
      const response = await fetch('/api/check_token', {
        credentials: 'include'
      });
      return response.ok;
    } catch (error) {
      console.error('Error checking token validity:', error);
      return false;
    }
  };

  const handleSuggestAIAnswer = async (mediaId, commentId, commentText) => {
    try {
      const response = await fetch('/api/suggest_comment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment: commentText }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to get AI suggestion');
      }

      const data = await response.json();
      setSuggestedReplies(prev => ({
        ...prev,
        [`${mediaId}-${commentId}`]: data.suggestion,
      }));
    } catch (error) {
      console.error('Error suggesting AI answer:', error);
      setError('Failed to get AI suggestion. Please try again.');
    }
  };

  const handleSendReply = async (mediaId, commentId) => {
    try {
      const reply = suggestedReplies[`${mediaId}-${commentId}`];
      const response = await fetch('/api/send_reply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mediaId, commentId, reply }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to send reply');
      }

      const data = await response.json();

      if (data.success) {
        setSuggestedReplies(prev => {
          const newReplies = { ...prev };
          delete newReplies[`${mediaId}-${commentId}`];
          return newReplies;
        });

        // Update the media state to include the new reply
        setMedia(prevMedia => 
          prevMedia.map(item => 
            item.id === mediaId 
              ? {
                  ...item, 
                  comments: [
                    ...item.comments.map(comment => 
                      comment.id === commentId
                        ? {
                            ...comment,
                            replies: {
                              data: [
                                ...(comment.replies?.data || []),
                                {
                                  id: data.reply.id,
                                  text: data.reply.text,
                                  timestamp: data.reply.timestamp,
                                  username: data.reply.username
                                }
                              ],
                              paging: comment.replies?.paging || { cursors: { after: '', before: '' } }
                            }
                          }
                        : comment
                    ),
                    {
                      id: data.reply.id,
                      text: data.reply.text,
                      timestamp: data.reply.timestamp,
                      username: data.reply.username,
                      parent_id: commentId
                    }
                  ],
                  comments_count: item.comments_count + 1
                } 
              : item
          )
        );

        console.log('Updated media after sending reply:', media);

        // Add the new comment to the newComments state
        setNewComments(prev => ({
          ...prev,
          [data.reply.id]: true
        }));

        // Remove the comment from newComments after animation
        setTimeout(() => {
          setNewComments(prev => {
            const updated = { ...prev };
            delete updated[data.reply.id];
            return updated;
          });
        }, 500); // Match this with the CSS animation duration

        alert('Reply sent successfully to Instagram!');
      } else {
        throw new Error(data.message || 'Failed to send reply to Instagram');
      }
    } catch (error) {
      console.error('Error sending reply:', error);
      setError('Failed to send reply. Please try again.');
      alert('Failed to send reply to Instagram. Please try again.');
    }
  };

  const updateCommentsWithNewReply = (comments, parentId, newReply) => {
    return comments.map(comment => {
      if (comment.id === parentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), { ...newReply, parent_id: parentId }]
        };
      } else if (comment.replies && comment.replies.length > 0) {
        return {
          ...comment,
          replies: updateCommentsWithNewReply(comment.replies, parentId, newReply)
        };
      }
      return comment;
    });
  };

  const handleSuggestedReplyChange = (mediaId, commentId, newReply) => {
    setSuggestedReplies(prev => ({
      ...prev,
      [`${mediaId}-${commentId}`]: newReply,
    }));
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      setError('Failed to logout. Please try again.');
    }
  };

  const handleNotificationClick = async (type) => {
    if (type === 'negativity') {
      try {
        const response = await fetch('/api/negative_comments', {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Failed to fetch negative comments');
        }
        const data = await response.json();
        setHighlightedComments(data.commentIds);
      } catch (error) {
        console.error('Error fetching negative comments:', error);
        setError('Failed to fetch negative comments. Please try again.');
      }
    }
  };

  const renderComments = (comments, mediaId, level = 0) => (
    <ul className={`comments-list ${level > 0 ? 'replies-list' : ''}`}>
      {comments.map(comment => (
        <li key={comment.id} className={`comment ${level > 0 ? 'reply' : ''} ${newComments[comment.id] ? 'new-comment' : ''} ${highlightedComments.includes(comment.id) ? 'highlighted-comment' : ''}`}>
          <div className="comment-header">
            <span className="comment-username">{comment.username}</span>
            <span className="comment-timestamp">{new Date(comment.timestamp).toLocaleString()}</span>
          </div>
          <p className="comment-text">{comment.text}</p>
          <div className="comment-actions">
            <button onClick={() => handleSuggestAIAnswer(mediaId, comment.id, comment.text)} className="action-button">
              <FaRobot /> Suggest AI Reply
            </button>
          </div>
          {suggestedReplies[`${mediaId}-${comment.id}`] && (
            <div className="suggested-reply-container">
              <textarea 
                value={suggestedReplies[`${mediaId}-${comment.id}`]}
                onChange={(e) => handleSuggestedReplyChange(mediaId, comment.id, e.target.value)}
                className="suggested-reply-textarea"
                placeholder="Edit suggested reply..."
              />
              <button onClick={() => handleSendReply(mediaId, comment.id)} className="send-reply-button">
                <FaPaperPlane /> Send Reply
              </button>
            </div>
          )}
          {comment.replies && comment.replies.length > 0 && (
            <div className="replies-container">
              {renderComments(comment.replies, mediaId, level + 1)}
            </div>
          )}
        </li>
      ))}
      {comments.length >= 50 && level === 0 && (
        <li className="load-more-comments">
          <button onClick={() => loadMoreComments(mediaId)}>Load more comments</button>
        </li>
      )}
    </ul>
  );
  
  const loadMoreComments = async (mediaId) => {
    try {
      const response = await fetch(`/api/load_more_comments/${mediaId}`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to load more comments');
      }
      const newComments = await response.json();
      setMedia(prevMedia => 
        prevMedia.map(item => 
          item.id === mediaId 
            ? { ...item, comments: [...item.comments, ...newComments] }
            : item
        )
      );
    } catch (error) {
      console.error('Error loading more comments:', error);
      setError('Failed to load more comments. Please try again.');
    }
  };
  
  const handleMediaUpdate = (newMedia) => {
    setMedia(prevMedia => [...newMedia, ...prevMedia]);
  };

  return (
    <div className="dashboard">
      <HeaderLoggedIn onNotificationClick={handleNotificationClick} />
      {error && <div className="error">{error}</div>}
      <InfiniteScroll
        dataLength={media.length}
        next={loadMoreMedia}
        hasMore={hasMore}
        loader={loadingMore ? <LoadingSpinner message="Loading more..." /> : null}
      >
        <div className="media-grid">
          {!loading && media.length === 0 ? (
            <p className="no-media">No media found.</p>
          ) : (
            media.map(item => (
              <div key={item.id} className="media-item">
                <div className="media-content">
                  {item.media_type === 'IMAGE' && (
                    <img src={item.media_url} alt={item.caption} className="media-image" />
                  )}
                  {item.media_type === 'VIDEO' && (
                    <img src={item.thumbnail_url} alt={item.caption} className="media-image" />
                  )}
                  <p className="media-caption">{item.caption}</p>
                  <a href={item.permalink} target="_blank" rel="noopener noreferrer" className="media-link">View on Instagram</a>
                  <p className="comments-count">Comments: {item.comments_count}</p>
                </div>
                <div className="comments-section">
                  {item.comments_count > 0 ? (
                    <>
                      <h3 className="comments-title">Comments:</h3>
                      {renderComments(organizeComments(item.comments), item.id)}
                    </>
                  ) : (
                    <p className="no-comments">No comments on this post.</p>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
      {loading && media.length === 0 && <LoadingSpinner message="Loading..." />}
      {animationVisible && (
        <div className="loading-animation">
          <LoadingSpinner message={animationMessage} />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
